import React from 'react';

import StatusList from '../components/StatusList';
import './iframe.scss';

const IframePage: React.FC = () => {
  return (
    <div className='iframe-container'>
      <div className='iframe-wrapper'>
        <StatusList />
      </div>
    </div>
  );
};

export default IframePage;
